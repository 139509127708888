import React from "react"
import Layout from "../components/layout/layout"
import styles from "./index.module.scss"
import InputButton from "../components/elements/inputButton"
import { Row, Col } from "reactstrap"
import { Link } from "gatsby"

export default () => (
  <Layout active="HOME">
    <section className={styles.sectionwhite}>
      <Row className={styles.wrsection + " p-0 m-0 mt-5 pt-4 w-100"}>
        <Col md="6" className="justify-content-center align-items-center">
          <div className={styles.homeContainer + " container my-auto pt-5"}>
            <h1 className={styles.subtittleOrange + " subtittleOrange mt-5"}>
              Marketplace for purchasing
            </h1>
            <h1 className={styles.subtittleYellow + " pb-3"}>
              claim rights in Loans.
            </h1>
            <p className={"pb-3 pt-1 w-75 text-center m-auto"}>
              Purchase claim rights in already disbursed loans and potentially
              start earning passive income. Explore the different Smart Products
              on offer, and put your money to work. <br /> <br />
              Connect with loan originators on our claims assignment
              marketplace.
            </p>
            <div
              className={styles.sendInfo + " d-flex justify-content-start pb-0"}
            >
              <InputButton
                svg="/images/icons/arrow-right.svg"
                alt="Arrow Right"
                holder="E-mail"
                type="email"
              />
            </div>
            {/* <div
              className={
                styles.textSmall + " d-none justify-content-center pb-5 m-3"
              }
            >
              <small>
                <p>
                  Your Capital is at Risk -
                  <Link to="/risk" className={styles.links + " ml-2"}>
                    Learn more about risks
                  </Link>
                </p>
              </small>
            </div> */}
          </div>
        </Col>
        <Col md="6" className="p-0 m-0">
          <div className={styles.containerGif + " d-none d-lg-block p-0 m-0"}>
            {/* <img
              src="/images/stock/travel.jpg"
              alt="Travel"
              className={styles.travel}
            /> */}
          </div>
        </Col>
      </Row>
    </section>
    <section className={styles.sectiongreen}>
      <Row className="p-0 m-0">
        {/* <Col xs="12">
          <div className={styles.homeContainer + " container my-auto"}>
            <h1 className={styles.subtittle + " pb-0 pt-0 text-center my-3"}>
              SmartMonday
            </h1>
          </div>
        </Col> */}
        <Col
          lg="4"
          className="justify-content-center justify-content-around align-items-center"
        >
          <div
            className={
              styles.iconcontainer +
              "container my-auto d-flex justify-content-center"
            }
          >
            <img
              src="/images/icons/icono-2.png"
              alt="Card"
              className={styles.icon + " my-0"}
            />
          </div>
          <div className="d-flex justify-content-center flex-column">
            <h4
              className={
                styles.titlesSectionTwo +
                " pb-2 pt-3 text-center mx-auto text-bold"
              }
            >
              <strong>Seamless Onboarding </strong>
            </h4>
            <p
              className={
                styles.textdark + " pb-4 pt-1 w-100 text-center mx-auto"
              }
            >
              We apply seamless and automated procedures to meet EU standards
              for KYC and Anti-Money Laundering procedures
            </p>
          </div>
        </Col>
        <Col
          xs="12"
          lg="4"
          className="justify-content-center justify-content-around align-items-center h-100"
        >
          <div
            className={
              styles.iconcontainer +
              "container my-auto d-flex justify-content-center"
            }
          >
            <img
              src="/images/icons/icono-3.png"
              alt="Card"
              className={styles.icon + " my-0"}
            />
          </div>
          <div className="d-flex justify-content-center flex-column">
            <h4
              className={
                styles.titlesSectionTwo +
                " pb-2 pt-3 text-center mx-auto text-bold"
              }
            >
              <strong> Multi-level profile protection</strong>
            </h4>
            <p
              className={
                styles.textdark + " pb-4 pt-1 w-100 text-center mx-auto"
              }
            >
              In order to access your user profile, 2 factor authentication is
              implemented to protect your profile.
            </p>
          </div>
        </Col>
        <Col
          xs="12"
          lg="4"
          className="justify-content-center justify-content-around align-items-center h-100"
        >
          <div
            className={
              styles.iconcontainer +
              "container my-auto d-flex justify-content-center "
            }
          >
            <img
              src="/images/icons/icono-1.png"
              alt="Card"
              className={styles.icon + " my-0"}
            />
          </div>
          <div className="d-flex justify-content-center flex-column">
            <h4
              className={
                styles.titlesSectionTwo +
                " pb-2 pt-3 text-center mx-auto text-bold"
              }
            >
              <strong> Notifications</strong>
            </h4>
            <p
              className={
                styles.textdark + " pb-4 pt-1 w-100 text-center mx-auto"
              }
            >
              Consult your live profile to see information on your claims,
              potential returns, or other transactions.
            </p>
          </div>
        </Col>
      </Row>
    </section>
    <section className={styles.sectionwhite + " pb-5"}>
      <h1
        className={styles.subtittleBorderStyle + " pb-0 pt-0 text-center my-5"}
      >
        Our Values
      </h1>
      <Row className="p-0 m-0 justify-content-around">
        <Col
          xs="12"
          lg="3"
          className={
            styles.cardItem +
            " justify-content-center justify-content-around align-items-center"
          }
        >
          <div className={styles.cardColumn}>
            <div
              className={
                styles.iconcontainer +
                "container my-auto d-flex justify-content-center"
              }
            >
              <div className={styles.circleIconStyle}>
                <img
                  src="/images/icons/happyClients.png"
                  alt="Card"
                  className={styles.icon + " my-0"}
                />
              </div>
            </div>
            <div className="d-flex justify-content-center flex-column">
              <h4
                className={
                  styles.titlesSectionTwo +
                  " pb-2 pt-3 text-center mx-auto text-bold"
                }
              >
                <strong>Inventions to simplify </strong>
              </h4>
              <p
                className={
                  styles.textdark + " pb-4 pt-1 w-100 text-center mx-auto"
                }
              >
                Finances are complicated enough. Therefore we have created a
                platform using cutting edge technology which is both easy to
                understand and to use.
              </p>
            </div>
          </div>
        </Col>
        <Col
          xs="12"
          lg="3"
          className={
            styles.cardItem +
            " " +
            styles.generalBackbroundOrange +
            " justify-content-center justify-content-around align-items-center"
          }
        >
          <div className={styles.cardColumn}>
            <div
              className={
                styles.iconcontainer +
                "container my-auto d-flex justify-content-center"
              }
            >
              <div className={styles.circleIconStyleOrange}>
                <img
                  src="/images/icons/trust.png"
                  alt="Card"
                  className={styles.icon + " my-0"}
                />
              </div>
            </div>
            <div className="d-flex justify-content-center flex-column">
              <h4
                className={
                  styles.textwhiteColor +
                  " " +
                  styles.titlesSectionTwoOrange +
                  " pb-2 pt-3 text-center mx-auto text-bold"
                }
              >
                <strong>Customer orientated </strong>
              </h4>
              <p
                className={
                  styles.textwhiteColor + " pb-4 pt-1 w-100 text-center mx-auto"
                }
              >
                We centre our solutions with customer satisfaction as our number
                one priority.
              </p>
            </div>
          </div>
        </Col>
        <Col
          xs="12"
          lg="3"
          className={
            styles.cardItem +
            " justify-content-center justify-content-around align-items-center"
          }
        >
          <div className={styles.cardColumn}>
            <div
              className={
                styles.iconcontainer +
                "container my-auto d-flex justify-content-center "
              }
            >
              <div className={styles.circleIconStyle}>
                <img
                  src="/images/icons/teamdiligent.png"
                  alt="Card"
                  className={styles.icon + " my-0"}
                />
              </div>
            </div>
            <div className="d-flex justify-content-center flex-column">
              <h4
                className={
                  styles.titlesSectionTwo +
                  " pb-2 pt-3 text-center mx-auto text-bold"
                }
              >
                <strong>The pursuit of excellence </strong>
              </h4>
              <p
                className={
                  styles.textdark + " pb-4 pt-1 w-100 text-center mx-auto"
                }
              >
                We believe in cultivating excellency by constantly innovating.
                We seek to inspire innovation by providing an excellent customer
                experience.
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </section>
    <section className={styles.sectiongreen}>
      <Row className="p-0 m-0 my-0">
        <Col
          xs="12"
          className="justify-content-center align-items-center h-100 my-auto"
        >
          <div>
            <h2
              className={
                styles.subtittle + " pb-3 pt-1 text-center mx-auto my-2"
              }
            >
              Getting Started
            </h2>
          </div>
        </Col>
        <Col
          xs="12"
          lg="4"
          className={
            "justify-content-center justify-content-around align-items-center h-100 my-auto"
          }
        >
          <div className={" container my-auto d-flex justify-content-center"}>
            <img
              src="/images/stock/woman-create-account.jpg"
              alt="Card"
              className={styles.getImg}
            />
          </div>
          <div className={"d-flex justify-content-center flex-column"}>
            <h4
              className={
                styles.textdark +
                " pb-4 pt-3 w-60 text-center mx-auto text-bold"
              }
            >
              1. Register
            </h4>
          </div>
        </Col>
        <Col
          xs="12"
          lg="4"
          className="justify-content-center justify-content-around align-items-center h-100 my-auto"
        >
          <div className={" container my-auto d-flex justify-content-center"}>
            <img
              src="/images/stock/girl-check-phone.jpg"
              alt="Card"
              className={styles.getImg}
            />
          </div>
          <div className="d-flex justify-content-center flex-column">
            <h4
              className={
                styles.textdark +
                " pb-2 pt-3 w-70 text-center mx-auto text-bold"
              }
            >
              2. Transfer funds to your segregated wallet held with a regulated
              Electronic Money Institution
            </h4>
          </div>
        </Col>
        <Col
          xs="12"
          lg="4"
          className="justify-content-center justify-content-around align-items-center h-100 my-auto"
        >
          <div className={" container my-auto d-flex justify-content-center"}>
            <img
              src="/images/stock/female-working-on-desktop.jpg"
              alt="Thinking Girl"
              className={styles.getImg}
            />
          </div>
          <div className="d-flex justify-content-center flex-column">
            <h4
              className={
                styles.textdark +
                " pb-3 pt-4 w-60 text-center mx-auto text-bold"
              }
            >
              3. Build your claims portfolio*
            </h4>
          </div>
        </Col>
        <Col
          xs="12"
          className="justify-content-center align-items-center h-100 my-auto"
        >
          <p
            className={
              styles.textOrange + " pb-1 pt-4 w-75 text-center mx-auto"
            }
          >
            Neither SmartMonday nor the loan originator is responsible for a
            borrower failing to repay their loan, including for late payments.
            In the case that a borrower is unable to repay the loan, investors
            may lose some or all of their invested capital.
          </p>
        </Col>
      </Row>
    </section>
    <section className={styles.sectionwhite}>
      <Col
        md="12"
        className={"justify-content-center align-items-center h-100 my-5 py-0"}
      >
        <div>
          <h2 className={styles.subtittle + " pb-3 text-center mx-auto my-0"}>
            Ready. Set. Go
          </h2>
          <p
            className={
              styles.generalTextTitleDark +
              " pb-1 pt-1 w-100 text-center mx-auto"
            }
          >
            Explore Smart Products now!
          </p>
          <div className={styles.sendInfo2 + " pb-1"}>
            <InputButton
              svg="/images/icons/arrow-right.svg"
              alt="Arrow Right"
              holder="E-mail"
              type="email"
            />
          </div>
        </div>
      </Col>
    </section>
  </Layout>
)
